import React from 'react';
import Layout from '../components/layout';

const Resources = () => {
  return (
    <Layout>
      <div style={{ paddingTop: '10rem' }} className="has-text-centered">
        <h3 className="is-size-3">Check back soon for our curriculum!</h3>
      </div>
    </Layout>
  );
};

export default Resources;
